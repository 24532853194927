'use client';

import { onError } from '@apollo/client/link/error';
import { usePathname, useSearchParams } from 'next/navigation';
import { ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client';

import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import possibleTypes from '@nerdwallet/apollo/possible-types';
import appConfig from '@nerdwallet/app-config';
import logger from '../../../logging';

const getCookie = (cookies: any, name: string) => {
  const cookie = cookies?.find((c: any) => c?.[0] === name);

  return cookie?.[1]?.value || null;
};
const getHeader = (headers: any, name: string) => {
  const header = headers?.find((h: any) => h?.[0] === name);

  return header?.[1] || null;
};

export const makeClient =
  ({ headers, cookies }: any) =>
  () => {
    const url = `${appConfig?.SITE_BASE_URL}${usePathname()}?${useSearchParams()}`;
    const clientHttpLink = new HttpLink({
      uri: process.env['BROWSER.SUPERGRAPH_URL'],
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'X-Application-ID': appConfig?._meta?.deployable,
        'X-Caller-Client-ID': appConfig?._meta?.deployable,
        'X-Client-Platform': 'web',
        'X-Client-Version': appConfig?._meta?.version,
        'X-Originating-Url': url,
        'User-Agent': headers ? getHeader(headers, 'user-agent') : '',
      },
    });

    const serverHttpLink = new HttpLink({
      uri: process.env.SUPERGRAPH_URL,
      headers: {
        'Content-type': 'application/json',
        'X-Application-ID': appConfig?._meta?.deployable,
        'X-Caller-Client-ID': appConfig?._meta?.deployable,
        'X-Client-Platform': 'server',
        'X-Client-Version': appConfig?._meta?.version,
        'X-Cookie-Id': headers ? getHeader(headers, 'x-cookie-id') : '',
        Cookie: headers ? getHeader(headers, 'cookie') : '',
        'User-Agent': headers ? getHeader(headers, 'user-agent') : '',
      },
    });
    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache({
        possibleTypes: possibleTypes.possibleTypes,
      }),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                  graphQLErrors.map(({ message, locations, path }) =>
                    // eslint-disable-next-line no-console
                    logger.log(
                      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                  );
                }
                // eslint-disable-next-line no-console
                logger.log(`[Network Error] ${networkError}`);
              }),

              new SSRMultipartLink({
                stripDefer: true,
              }),
              serverHttpLink,
            ])
          : ApolloLink.from([
              onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                  graphQLErrors.map(({ message, locations, path }) =>
                    // eslint-disable-next-line no-console
                    logger.log(
                      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                  );
                }
                // eslint-disable-next-line no-console
                logger.log(`[Network Error] ${networkError}`);
              }),
              clientHttpLink,
            ]),
      // Disable caching to ensure static pages always fetch fresh data
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  };

export default makeClient;
